import React from "react"

const ContactUs = () => {
  return (
    <div>
      <section id="contact-us">
        <h2>Contact Us</h2>
        <hr />
        <div className="message">
          <div className="message-body">
            Feel free to reach us at{" "}
            <a href="mailto:kumustadok@medgrocer.com" target="_blank">
              kumustadok@medgrocer.com
            </a>{" "}
            or 0917 835 6313 for any questions.
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUs
