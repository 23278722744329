import React, { Fragment } from "react"

import Layout from "layout/Layout"
import Container from "layout/Container"
import Collapsible from "elements/Collapsible"

import ContactUs from "./HelpCenter/ContactUs"

const HelpCenter = props => {
  const { helpCenterContent, module } = props.pageContext

  return (
    <Layout seoTitle={module.seoTitle}>
      <Container isCentered>
        <h2>Frequently Asked Questions</h2>
        <hr />
        {helpCenterContent.map(section => {
          return (
            <Fragment>
              <h4>{section.header}</h4>
              {section.fields.map(faq => {
                return (
                  <Collapsible title={faq.question}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.childMarkdownRemark.html,
                      }}
                    ></p>
                  </Collapsible>
                )
              })}
            </Fragment>
          )
        })}
        <ContactUs />
      </Container>
    </Layout>
  )
}

export default HelpCenter
